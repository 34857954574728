import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import TeamLeader from './Flows/TeamLeader/TeamLeader';
import Student from './Flows/Student/Student';
import TeamMember from "./Flows/TeamMember/TeamMember";
import ClubAdmin from "./Flows/ClubAdmin/ClubAdmin";
import SuperAdmin from "./Flows/SuperAdmin/SuperAdmin";
import Trainer from "./Flows/ClubTrainer/Trainer";
import Landing from "./landing/Landing";
import ProtectedRoute from "./components/ProtectedRoutes/ProtectedRoutes";
import ContactUs from "./pages/ContactUs";
import Disclaimer from "./pages/Disclaimer";
import TermsAndConditions from "./pages/TermsAndCondition";
import RefundPolicy from "./pages/RefundPolicy";
import NotFound from './landing/NotFound/NotFound';
import CoursePage from './pages/CoursePage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/student" element={<Student />} />
        <Route path="/student/dashboard"
          element={
            <ProtectedRoute>
              <Student />
            </ProtectedRoute>
          }
        />

        <Route path="/teamleader" element={<TeamLeader />} />
        <Route path="/teamleader/dashboard"
          element={
            <ProtectedRoute>
              <TeamLeader />
            </ProtectedRoute>
          }
        />

        <Route path="/teammember" element={<TeamMember />} />
        <Route path="/teammember/dashboard"
          element={
            <ProtectedRoute>
              <TeamMember />
            </ProtectedRoute>
          }
        />

        <Route path="/trainer" element={<Trainer />} />
        <Route path="/trainer/dashboard"
          element={
            <ProtectedRoute>
              <Trainer />
            </ProtectedRoute>
          }
        />


        <Route path="/clubadmin" element={<ClubAdmin />} />
        <Route path="/clubadmin/dashboard"
          element={
            <ProtectedRoute>
              <ClubAdmin />
            </ProtectedRoute>
          }
        />

        <Route path="/superadmin" element={<SuperAdmin />} />
        <Route path="/superadmin/dashboard"
          element={
            <ProtectedRoute>
              <SuperAdmin />
            </ProtectedRoute>
          }
        />

        <Route path="/" element={<Landing />} />
        <Route path='/courses' element={<CoursePage />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
        <Route path="/tnc" element={<TermsAndConditions />} />
        <Route path="/refund-policy" element={<RefundPolicy />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router >
  );
}

export default App;
