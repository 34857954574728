// ButtonComponent.tsx
import React from 'react';
import styles from './ButtonComponent.module.css';

const ButtonComponent = ({ buttonType = 'button', buttonText, onClick, className, disabled = false, disabledText = "loading" }) => {
    return (
        <button
            type={buttonType}
            onClick={onClick}
            disabled={disabled}
            className={`${styles.btn} ${className ? className : ''} ${disabled ? styles.btnDisabled : ''}`}
        >
            {disabled ? disabledText : buttonText}
        </button>
    );
};

export default ButtonComponent;
