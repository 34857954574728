import React from 'react'
import styles from "./prize.module.css"
import SlotMachine from '../../../../components/FlowComponents/SlotMachine/SlotMachine'
import Gift from '../Gift/Gift'


const Prize = () => {

  return (
    <div
      className={styles.prize}
    >

      <SlotMachine />
      <Gift />

    </div>
  )
}

export default Prize