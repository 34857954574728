import React from 'react';
import styles from './Feedback.module.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaQuoteRight } from "react-icons/fa6";
import { FaGoogle } from "react-icons/fa";
import { FaStar, FaStarHalf } from 'react-icons/fa'

function Feedback() {
    const feedback = [
        {
            userImg: "https://lh3.googleusercontent.com/a-/ALV-UjVQYEN5Vw2K0VZeOI_6681J382tBkI40zQSuYZYpZ2FoyW3CQsy=w120-h120-p-rp-mo-br100",
            userName: "Priyanka Dey",
            userReview: "At present I am taking training under Affiliate Ritual institution. Now my one months training is completed and 2 more months left. I m very satisfied to take training under Supriyo sir. He is very helpful and kind person. All the members of center is Same as Supriyo sir. Very much understanding and helpful. I can grow myself under his training. Thank you sir for supporting us.",
            userRating: 5,
        },
        {
            userImg: "https://lh3.googleusercontent.com/a-/ALV-UjUxX5qv1IR6hLjuCBmYUQKxe1VlMCup9XPv2mzOOrnNU5CmAo8=w120-h120-p-rp-mo-br100",
            userName: "Spandan Saha",
            userReview: "Hello my name is Spandan Saha. I am a student of Affiliate Ritual for past 1.5 months to learn about Digital Marketing, Social Media Management, Content Creation and how to survive in the online world as a Freelancer. The course is excellent and I am thoroughly enjoying the course . Digital Coach Supriyo Sir is an great Coach who keeps his students and their problems his top priority and fills their life with positivity. All the courses of Affiliate Ritual are must recommend for those who want to get out of the 9-5 circle and make their name in the online world.",
            userRating: 4,
        },
        {
            userImg: "https://lh3.googleusercontent.com/a-/ALV-UjXYccgR8ng9yDyHwJcnEfkWrzE5tit2kPb_utrb1zbrVF-bAHFB=w120-h120-p-rp-mo-ba2-br100",
            userName: "Sangita Bishnu",
            userReview: "I've been learning freelancing from Supriyo sir and it's been an amazing experience.. The tips and tricks, and his guidance have boosted my confidence. If you aspire to become a freelancer, I wholeheartedly recommend joining Affiliate Ritual.",
            userRating: 5,
        },
        {
            userImg: "https://lh3.googleusercontent.com/a-/ALV-UjWOQfSY2VkfKpDlSKJ6iwYA_D2fsIMZnUYvKPuwq9rpa9ekJiFA=w120-h120-p-rp-mo-br100",
            userName: "Chayan Roy",
            userReview: "#Digitalcoach Supriyo Sir is too much helpful & supportive..😊😎.As a freelancer I am feeling great to learn form Affiliate Ritual. On this upcoming day every IT related student's should learn from this institute & basically Digital Marketing on AI based tool.",
            userRating: 5,
        },
        {
            userImg: "https://lh3.googleusercontent.com/a-/ALV-UjVEte_t10GxVqht1BVqJCAnKvCAhVYTcyY8oVnjE8QNP1uRn7yx=w120-h120-p-rp-mo-br100",
            userName: "Shibam Biswas",
            userReview: "Hi! My name is Shibam Biswas. I'm currently learning Digital Marketing course from Affiliate Ritual. I am happy to say that my overall experience in Affiliate Ritual is very good till now. I am eager to learn most of the things of Digital Marketing. Thanks Supriyo sir for guiding me through this journey. No doubt this is the best digital marketing institute in Kolkata.",
            userRating: 4,
        },
    ]

    const CustomPrevArrow = (props) => {
        const { className, onClick } = props;
        return <div className={`${styles.customArrow} ${styles.prev}`} onClick={onClick} />;
    };

    const CustomNextArrow = (props) => {
        const { className, onClick } = props;
        return <div className={`${styles.customArrow} ${styles.next}`} onClick={onClick} />;
    };


    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        autoplay: true, // Enables automatic sliding
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: true,
        arrows: true, // Ensure arrows are enabled
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };

    return (
        <div className={styles.feedbackMain}>
            <div className={styles.rating}>
                <span className={styles.score}>5.0</span>
                <div className={styles.stars}>
                    <FaStar className={styles.starFilled} />
                    <FaStar className={styles.starFilled} />
                    <FaStar className={styles.starFilled} />
                    <FaStar className={styles.starFilled} />
                    <FaStar className={styles.starFilled} />
                    {/* <FaStarHalf className={styles.starFilled} /> */}
                </div>
                <span className={styles.reviews}>(115,521)</span>
            </div>

            <div>
                <Slider {...settings} className={styles.feedbackContainer}>
                    {feedback.map((item, i) => (
                        <div key={i} className={styles.feedbackItem}>
                            <div className={styles.feedbackItpWrapper}>
                                <img
                                    className={styles.feedbackIcon}
                                    src={item.userImg}
                                />
                                <div className={styles.feedbackTpWrapper}>
                                    {/* google user name */}
                                    <div className={styles.feedbackTitle}>{item.userName}</div>

                                    {/* google ratings - star */}
                                    <div className={styles.stars}>
                                        {Array.from({ length: 5 }, (_, index) => (
                                            <FaStar
                                                key={index}
                                                className={index < item.userRating ? styles.starFilled : styles.starEmpty}
                                            />
                                        ))}
                                    </div>
                                </div>
                                <FaGoogle className={styles.feedbackQuoteRight} />
                            </div>
                            <div className={styles.feedbackDescription}>{item.userReview}</div>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
}

export default Feedback;
