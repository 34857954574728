import React from 'react';
import styles from '../../../styles/DashboardStyles/Sidebar.module.css';
import logo from "../../../logo.png"
import logoutPng from "./assets/logout.png"
import dashActive from "./assets/dashActive.png";
import dash from "./assets/dash.png";
import CourseActive from "./assets/CourseActive.png";
import Course from "./assets/Course.png";
import GiftsActive from "./assets/giftActive.png";
import Gifts from "./assets/gift.png";
import PayoutsActive from "./assets/payoutActive.png";
import Payouts from "./assets/payout.png";
import SaleTargetActive from "./assets/SaleActive.png";
import SaleTarget from "./assets/Sale.png";

const Sidebar = ({ activeElement, handleClick, openModal, isMenuExpanded }) => {
    console.log(isMenuExpanded);
    return (
        <div className={`${styles.sidebar} ${isMenuExpanded ? styles.collapseSidebar : ''}`}>

            <div className={styles.elementWrapper}>
                <div className={styles.heading}>
                    <img
                        src={logo}
                        alt="Logo"
                        className={styles.headingLogo}
                    />
                </div>

                <div
                    className={activeElement === 'admindash' ? styles.sidebarelementactive : styles.sidebarelement}
                    onClick={() => handleClick('admindash')}
                >
                    <img src={activeElement === 'admindash' ? dashActive : dash} alt="Admin Dash" />
                    <p className={`${styles.elementText} ${isMenuExpanded ? styles.hideElementText : ''}`}>Admin Dash</p>
                </div>

                <div
                    className={activeElement === 'courselist' ? styles.sidebarelementactive : styles.sidebarelement}
                    onClick={() => handleClick('courselist')}
                >
                    <img
                        src={activeElement === 'courselist' ? CourseActive : Course}
                        alt="Courselist"
                    />
                    <p className={`${styles.elementText} ${isMenuExpanded ? styles.hideElementText : ''}`}>Course List</p>
                </div>

                <div
                    className={activeElement === 'payout' ? styles.sidebarelementactive : styles.sidebarelement}
                    onClick={() => handleClick('payout')}
                >
                    <img
                        src={activeElement === 'payout' ? PayoutsActive : Payouts}
                        alt="payout"
                    />
                    <p className={`${styles.elementText} ${isMenuExpanded ? styles.hideElementText : ''}`}>Payout</p>
                </div>

                <div
                    className={activeElement === 'gift' ? styles.sidebarelementactive : styles.sidebarelement}
                    onClick={() => handleClick('gift')}
                >
                    <img
                        src={activeElement === 'gift' ? GiftsActive : Gifts}
                        alt="gift"
                    />
                    <p className={`${styles.elementText} ${isMenuExpanded ? styles.hideElementText : ''}`}>Gift</p>
                </div>

                <div
                    className={activeElement === 'saletarget' ? styles.sidebarelementactive : styles.sidebarelement}
                    onClick={() => handleClick('saletarget')}
                >
                    <img
                        src={activeElement === 'saletarget' ? SaleTargetActive : SaleTarget}
                        alt="saletarget"
                    />
                    <p className={`${styles.elementText} ${isMenuExpanded ? styles.hideElementText : ''}`}>Sale Target</p>
                </div>

                <div
                    className={activeElement === 'addTeamLeader' ? styles.sidebarelementactive : styles.sidebarelement}
                    onClick={() => handleClick('addTeamLeader')}
                >
                    <img
                        src={activeElement === 'addTeamLeader' ? SaleTargetActive : SaleTarget}
                        alt="addTeamLeader"
                    />
                    <p className={`${styles.elementText} ${isMenuExpanded ? styles.hideElementText : ''}`}>Add Leader</p>
                </div>
            </div>

            <div className={styles.logoutWrapper} onClick={openModal}>
                <img src={logoutPng} alt="Logout" className={styles.logout} />
                <p
                    className={`${styles.elementText} ${isMenuExpanded ? styles.hideElementText : ''}`}
                >Logout</p>
            </div>
        </div>
    );
};

export default Sidebar;
