import React, { useState, useEffect } from "react";
import Logout from "../../../components/LogoutModal/Logout";
import styles from "../../../styles/DashboardStyles/Dashboard.module.css"
import Prize from "./Prizes/Prize";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Gift from "./Gift/Gift";
import Dash from "./Dash/Dash";
import MemberReviews from "./Reviews/MemberReviews";
import UserNotification from "../../../components/FlowComponents/userNotification/UserNotification";
import UserProfile from "../../../components/FlowComponents/userProfile/UserProfile";
import { sampleNotificationData } from "../../../service/notificationService/sampleNotificationData";
import { useAuth } from "../../../provider/Auth.provider";
import { findUserByUID } from "../../../service/findUsers/findUserByUID";
import logout from "../../../service/logout";


const MemberDashboard = () => {
  const { user } = useAuth();
  const [userData, setUserData] = useState({});

  const [showModal, setShowModal] = useState(false);
  const [notifyModal, setNotifyModal] = useState(false);
  const [profileModal, setProfileModal] = useState(false);

  const [activeElement, setActiveElement] = useState('dash');
  const [isMenuExpanded, setIsMenuExpanded] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      const userData = await findUserByUID(user.uid);
      setUserData(userData);
    };
    fetchUserData();
  }, [user]);


  useEffect(() => {
    const handleResize = () => {
      setIsMenuExpanded(false);
    };
    // Add event listener for window resize
    window.addEventListener("resize", handleResize);
    // Initial check on component mount
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  const handleClick = (element) => setActiveElement(element);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1168) {
        setIsMenuExpanded(true);
      } else {
        setIsMenuExpanded(false);
      }
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Initial check on component mount
    handleResize();

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  return (
    <div className={`${styles.dashboard}`}>
      <Sidebar
        activeElement={activeElement}
        handleClick={handleClick}
        openModal={() => setShowModal(true)}
        isMenuExpanded={isMenuExpanded}
      />

      <div className={styles.maincontent}>
        <Header
          userData={userData}
          openNotifyModal={() => setNotifyModal(true)}
          openProfileModal={() => setProfileModal(true)}
          toggleMenu={() => setIsMenuExpanded(!isMenuExpanded)}
        />
        {activeElement === 'dash' ? <Dash /> : null}
        {activeElement === 'prizes' ? <Prize /> : null}
        {activeElement === 'reviews' ? <MemberReviews /> : null}
        {activeElement === 'gift' ? <Gift /> : null}
      </div>

      <Logout
        showModal={showModal}
        closeModal={() => setShowModal(false)}
        handleLogout={logout}
      />

      <UserNotification
        showModal={notifyModal}
        closeModal={() => setNotifyModal(false)}
        sampleNotificationData={sampleNotificationData}
      />
      <UserProfile
        userData={userData}
        showModal={profileModal}
        closeModal={() => setProfileModal(false)}
      />

    </div>
  );
};

export default MemberDashboard;
