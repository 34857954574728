import { useEffect, useState } from "react";
import { useAuth } from "../../../provider/Auth.provider"
import styles from "../../../styles/DashboardStyles/Dashboard.module.css"
import Sidebar from "./Sidebar"
import Header from "./Header"
import Couselist from "./CourseList/Couselist"
import Gift from "./Gift/Gift"
import Payout from "./Payout/Payout"
import SuperDa from "./SuperDash/SuperDashboard";
import SalesTarget from "./SaleTarget/SaleTarget";
import Logout from "../../../components/LogoutModal/Logout";
import logout  from "../../../service/logout"
import { findUserByUID } from "../../../service/findUsers/findUserByUID"
import UserNotification from "../../../components/FlowComponents/userNotification/UserNotification";
import UserProfile from "../../../components/FlowComponents/userProfile/UserProfile";
import { sampleNotificationData } from "../../../service/notificationService/sampleNotificationData";
import AddTeamLeader from "../../../components/AdminComponents/AddTeamLeader/AddTeamLeader";


const Dashboard = () => {
  const { user } = useAuth();
  const [userData, setUserData] = useState({});

  const [showModal, setShowModal] = useState(false);
  const [notifyModal, setNotifyModal] = useState(false);
  const [profileModal, setProfileModal] = useState(false);

  const [activeElement, setActiveElement] = useState('admindash');
  const [isMenuExpanded, setIsMenuExpanded] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      const userData = await findUserByUID(user.uid);
      setUserData(userData);
    };
    fetchUserData();
  }, [user]);


  useEffect(() => {
    const handleResize = () => {
      setIsMenuExpanded(false);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  const handleClick = (element) => setActiveElement(element);

  return (
    <div className={`${styles.dashboard}`}>
      <Sidebar
        activeElement={activeElement}
        handleClick={handleClick}
        openModal={() => setShowModal(true)}
        isMenuExpanded={isMenuExpanded}
      />
      <div className={styles.maincontent}>
        <Header
          userData={userData}
          openNotifyModal={() => setNotifyModal(true)}
          openProfileModal={() => setProfileModal(true)}
          toggleMenu={() => setIsMenuExpanded(!isMenuExpanded)}
        />
        {activeElement === 'admindash' ? <SuperDa /> : null}
        {activeElement === 'courselist' ? <Couselist /> : null}
        {activeElement === 'gift' ? <Gift /> : null}
        {activeElement === 'payout' ? <Payout /> : null}
        {activeElement === 'saletarget' ? <SalesTarget /> : null}
        {activeElement === 'addTeamLeader' ? <AddTeamLeader /> : null}
      </div>

      {/* this is modal, it will open when true, independent in itself */}
      <Logout
        showModal={showModal}
        closeModal={() => setShowModal(false)}
        handleLogout={logout}
      />
      <UserNotification
        showModal={notifyModal}
        closeModal={() => setNotifyModal(false)}
        sampleNotificationData={sampleNotificationData}
      />
      <UserProfile
        userData={userData}
        showModal={profileModal}
        closeModal={() => setProfileModal(false)}
      />
    </div>
  );
};

export default Dashboard;
