import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from './Proof.module.css';
import proof01 from "./proof01.webp";
import proof34 from "./proof34.webp";
import proof35 from "./proof35.webp";
import proof36 from "./proof36.webp";

// import proof39 from "./proof39.jpg"
// import proof42 from "./proof42.jpg"

function Proof() {
    const proof = [
        {
            imgLink: proof01,
            alt: "alt name"
        },
        {
            imgLink: proof34,
            alt: "alt name"
        },
        {
            imgLink: proof35,
            alt: "alt name"
        },
        {
            imgLink: proof36,
            alt: "alt name"
        },
    ];

    const CustomPrevArrow = (props) => {
        const { className, onClick } = props;
        return <div className={`${styles.customArrow} ${styles.prev}`} onClick={onClick} />;
    };

    const CustomNextArrow = (props) => {
        const { className, onClick } = props;
        return <div className={`${styles.customArrow} ${styles.next}`} onClick={onClick} />;
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        autoplay: true, // Enables automatic sliding
        slidesToShow: 5,
        slidesToScroll: 1,
        centerMode: true,
        arrows: true, // Ensure arrows are enabled
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };

    return (
        <div className={styles.proofContainer}>
            <p className={styles.proofParagraph}>
                Not enough? Do you want more? Come with me. I have something for you and that will definitely give you satisfaction!
            </p>

            <div className={styles.proofItems}>
                <Slider {...settings}>
                    {proof.map((item, i) => (
                        <div key={i}>
                            <img
                                className={styles.proofImg}
                                src={item.imgLink}
                                alt={item.alt}
                            />
                        </div>
                    ))}
                </Slider>
            </div>

        </div>
    );
}

export default Proof;
