import React from 'react';
import styles from '../../../styles/DashboardStyles/Sidebar.module.css';
import logo from "../../../logo.png"
import prize from "../../../assets/gift.png"
import prizeActive from "../../../assets/giftActive.png"
import logout from "./assets/logout.png";
import direct from "./assets/direct.png"
import directActive from "./assets/directActive.png";
import sales from "./assets/sales.png";
import salesActive from "./assets/salesActive.png";
import teamRep from "./assets/teamRep.png";
import teamRepActive from "./assets/teamRepActive.png";

const Sidebar = ({ activeElement, handleClick, openModal, isMenuExpanded }) => {
    console.log(isMenuExpanded);
    return (
        <div className={`${styles.sidebar} ${isMenuExpanded ? styles.collapseSidebar : ''}`}>

            <div className={styles.elementWrapper}>
                <div className={styles.heading}>
                    <img
                        src={logo}
                        alt="Logo"
                        className={styles.headingLogo}
                    />
                </div>
                <div
                    className={activeElement === 'sales' ? styles.sidebarelementactive : styles.sidebarelement}
                    onClick={() => handleClick('sales')}
                >
                    <img src={activeElement === 'sales' ? salesActive : sales} alt="sales" />
                    <p className={`${styles.elementText} ${isMenuExpanded ? styles.hideElementText : ''}`}>Sales</p>
                </div>
                <div
                    className={activeElement === 'teamReport' ? styles.sidebarelementactive : styles.sidebarelement}
                    onClick={() => handleClick('teamReport')}
                >
                    <img src={activeElement === 'teamReport' ? teamRepActive : teamRep} alt="teamReport" />
                    <p className={`${styles.elementText} ${isMenuExpanded ? styles.hideElementText : ''}`}>Team Report</p>
                </div>
                <div
                    className={activeElement === 'direct' ? styles.sidebarelementactive : styles.sidebarelement}
                    onClick={() => handleClick('direct')}
                >
                    <img src={activeElement === 'direct' ? directActive : direct} alt="direct" />
                    <p className={`${styles.elementText} ${isMenuExpanded ? styles.hideElementText : ''}`}>Direct Sale</p>
                </div>
                <div
                    className={activeElement === 'prize' ? styles.sidebarelementactive : styles.sidebarelement}
                    onClick={() => handleClick('prize')}
                >
                    <img src={activeElement === 'prize' ? prizeActive : prize} alt="prize" />
                    <p className={`${styles.elementText} ${isMenuExpanded ? styles.hideElementText : ''}`}>Prize</p>
                </div>
            </div>

            <div className={styles.logoutWrapper} onClick={openModal}>
                <img src={logout} alt="Logout" className={styles.logout} />
                <p
                    className={`${styles.elementText} ${isMenuExpanded ? styles.hideElementText : ''}`}
                >Logout</p>
            </div>
        </div>
    );
};

export default Sidebar;

