import React from 'react';
import close from "../../../../assets/close.png"
import styles from "../../../../styles/Modal.module.css"
import ButtonComponent from '../../../CssComponents/ButtonComponent/ButtonComponent';

const BuyModal = ({ gift, showModal = true, closeModal }) => {

    const handleBuy = () => {
        alert(`You have bought ${gift.giftName} for ${gift.coinRequired} coins!`);
        closeModal();
    };

    return (
        <div
            className={showModal ? `${styles.modal} ${styles.displayBlock}` : `${styles.modal} ${styles.displayNone}`}
        >
            <section className={styles.modalMain}>
                <div
                    className={styles.closebtn}
                    onClick={closeModal}>
                    <img src={close} alt="" />
                </div>
                <div className={styles.mainc}>
                    <h1 className={styles.heading}>Are you sure you want to buy <strong>{gift.giftName}</strong> for <strong>{gift.coinRequired} Coins</strong>?</h1>
                    <div className={styles.btnc}>
                        <ButtonComponent
                            onClick={closeModal}
                            className={styles.btn}
                            buttonText={"Cancel"}
                        />
                        <ButtonComponent
                            onClick={handleBuy}
                            className={styles.btn}
                            buttonText={"Buy Gifts"}
                        />
                    </div>
                </div>
            </section>
        </div>
    );
};

export default BuyModal;
