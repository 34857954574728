import React, { useState, useEffect } from 'react';
import { collection, getDocs } from "firebase/firestore";
import { db } from '../../../../firebase-config';
import styles from './Gift.module.css';
import BuyModal from './BuyModal/BuyModal'
import ButtonComponent from "../../../../components/CssComponents/ButtonComponent/ButtonComponent"

const Gift = () => {
  const [gifts, setGifts] = useState([]);
  const [selectedGift, setSelectedGift] = useState(null);

  useEffect(() => {
    const fetchGifts = async () => {
      const querySnapshot = await getDocs(collection(db, "gifts"));
      const giftsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setGifts(giftsData);
    };

    fetchGifts();
  }, []);

  console.log(gifts)


  return (
    <div className={styles.container}>
      {gifts.map((gift) => (
        <div key={gift.id} className={styles.giftCard}>
          <img src={"https://anuragaffection.gklite.in/assets/anuragImage-14d2f8e0.jpg"} alt={gift.giftName} className={styles.giftImage} />
          <div>Gift Name : {gift.giftName}</div>
          <div>Gift Price: {gift.coinRequired} Coins</div>
          <ButtonComponent
            onClick={() => setSelectedGift(gift)}
            buttonText={"Buy Gift"}
          />
        </div>
      ))}

      {selectedGift && (
        <BuyModal gift={selectedGift} closeModal={() => setSelectedGift(null)} />
      )}
    </div>
  );
};

export default Gift;
