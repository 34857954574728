import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Signin from './Signin/Signin';
import Register from './Register/StudentRegister.jsx';
import Dashboard from "./Dashboard/Dashboard.js";
import { useAuth } from '../../provider/Auth.provider.jsx';
import { notification } from 'antd';
import { findUserByUID } from "../../service/findUsers/findUserByUID.js";
import SignFooter from '../../components/FlowComponents/SignFooter/SignFooter.jsx';


const Student = () => {
  const { user, loading } = useAuth();
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [isRegistering, setIsRegistering] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    setIsRegistering(searchParams.get('action') === 'register');
  }, [location.search]);

  useEffect(() => {
    const checkUserAccess = async () => {
      try {
        if (!user?.uid) {
          notification.error({
            message: 'Credentials not found',
            description: 'Login to access dashboard',
            placement: 'topRight',
            duration: 3,
          });
          setIsSignedIn(false);
          return;
        }
        const userDetails = await findUserByUID(user.uid);
        if (userDetails?.userTypes === 'team_leader') {
          setIsSignedIn(true);
          if (location.pathname !== "/student/dashboard") {
            navigate('/student/dashboard', { replace: true });
          }
        }
      } catch (error) {
        notification.error({
          message: 'Error',
          description: 'An error occurred while checking user access',
          placement: 'topRight',
          duration: 3,
        });
        setIsSignedIn(false);
      }
    };

    checkUserAccess();
  }, [user, navigate, location]);

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      {
        !isSignedIn ? (
          isRegistering ? (
            <>
              <Register />
              <SignFooter />
            </>
          ) : (
            <>
              <Signin />
              <SignFooter />
            </>
          )
        ) : (
          <Dashboard />
        )
      }
    </>
  );
};

export default Student;
