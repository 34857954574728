import React from 'react'
import CoursePackage from '../Flows/Student/Dashboard/CoursePackage/CoursePackage'
import SignFooter from '../components/FlowComponents/SignFooter/SignFooter'

function CoursePage() {
    return (
        <div>
            <CoursePackage />
            <SignFooter />
        </div>
    )
}

export default CoursePage