import React, { useState, useEffect } from "react";
import styles from "../../../styles/DashboardStyles/Dashboard.module.css"
import Direct from "./Direct/Direct";
import Sales from "./Sales/Sales";
import TeamRep from "./TeamRep/TeamRep";
import logout from "../../../service/logout";
import Logout from "../../../components/LogoutModal/Logout";
import UserNotification from "../../../components/FlowComponents/userNotification/UserNotification"
import UserProfile from "../../../components/FlowComponents/userProfile/UserProfile"
import { sampleNotificationData } from "../../../service/notificationService/sampleNotificationData";
import { useAuth } from "../../../provider/Auth.provider";
import { findUserByUID } from "../../../service/findUsers/findUserByUID";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Gift from "../../../components/FlowComponents/Gift/Gift";
import Prize from "./Prizes/Prize";


const Dashboard = () => {
    const { user } = useAuth();
    const [userData, setUserData] = useState({});

    const [showModal, setShowModal] = useState(false);
    const [notifyModal, setNotifyModal] = useState(false);
    const [profileModal, setProfileModal] = useState(false);

    const [activeElement, setActiveElement] = useState('sales');
    const [isMenuExpanded, setIsMenuExpanded] = useState(false);


    useEffect(() => {
        const fetchUserData = async () => {
            const userData = await findUserByUID(user.uid);
            setUserData(userData);
        };
        fetchUserData();
    }, [user]);


    useEffect(() => {
        const handleResize = () => {
            setIsMenuExpanded(false);
        };
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);


    const handleClick = (element) => setActiveElement(element);

    return (
        <div
            className={`${styles.dashboard}`}
        >
            <Sidebar
                activeElement={activeElement}
                handleClick={handleClick}
                openModal={() => setShowModal(true)}
                isMenuExpanded={isMenuExpanded}
            />

            <div className={styles.maincontent}>
                <Header
                    userData={userData}
                    openNotifyModal={() => setNotifyModal(true)}
                    openProfileModal={() => setProfileModal(true)}
                    toggleMenu={() => setIsMenuExpanded(!isMenuExpanded)}
                />
                {activeElement === 'sales' ? <Sales /> : null}
                {activeElement === 'direct' ? <Direct userData={userData} /> : null}
                {activeElement === 'teamReport' ? <TeamRep /> : null}
                {activeElement === 'prize' ? <Prize /> : null}
            </div>

            <Logout
                showModal={showModal}
                closeModal={() => setShowModal(false)}
                handleLogout={logout}
            />
            <UserNotification
                showModal={notifyModal}
                closeModal={() => setNotifyModal(false)}
                sampleNotificationData={sampleNotificationData}
            />
            <UserProfile
                userData={userData}
                showModal={profileModal}
                closeModal={() => setProfileModal(false)}
            />
        </div>
    );
};

export default Dashboard;
