import React from 'react'
import styles from "./UserProfileCard.module.css"
import Arrow from "./arrow.png"

function UserProfileCard({ item, onBack }) {

    const user = {
        name: "Anurag Affection",
        profileImage: "https://logo.gklite.in/anuragaffection.jpg",
        earnings: 5000.75,
        joiningDate: "2023-01-15",
        totalMembers: 120
    };

    return (
        <div className={styles.mainContainer}>
            <div className={styles.container}>
                <button className={styles.backButton} onClick={onBack}>
                    <img src={Arrow} alt="" />
                </button>

                <div>
                    <div className="">Team Report</div>
                    <div className="">Team Report/{item.firstName + " " + item.lastName}</div>
                </div>

                <div className={styles.userProfile}>
                    <img src={user.profileImage} alt={user.name} className={styles.profileImage} />
                </div>

                <div className={styles.userInfo}>
                    <div className={styles.infoWrapper}>
                        <div className={styles.userLabel}>Username:</div>
                        <div className={styles.userPara}>{item.firstName + " " + item.lastName}</div>
                    </div>
                    <div className={styles.infoWrapper} >
                        <div className={styles.userLabel}>Earnings:</div>
                        <div className={styles.userPara}>{user.earnings.toFixed(2)} </div>
                    </div>
                    <div className={styles.infoWrapper} >
                        <div className={styles.userLabel}>Joined:</div>
                        <div className={styles.userPara}>{item.createdAt}</div>
                    </div>
                    <div className={styles.infoWrapper} >
                        <div className={styles.userLabel}>Total Members:</div>
                        <div className={styles.userPara}>{user.totalMembers} </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserProfileCard