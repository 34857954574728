import React, { lazy, Suspense, useCallback, useEffect, useMemo, useState } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import Header from "./header/Header";
import Footer from "./Footer/Footer"
import Main from "./main/Main"
const HeaderLoginOptions = lazy(() => import("../components/LandingComponents/HeaderLoginOptions/HeaderLoginOptions"));
const HeaderRegisterOptions = lazy(() => import("../components/LandingComponents/HeaderRegisterOptions/HeaderRegisterOptions"));

const Landing = () => {
    useEffect(() => {
        const originalStyles = {
            fontFamily: document.body.style.fontFamily,
            margin: document.body.style.margin,
            padding: document.body.style.padding,
            boxSizing: document.body.style.boxSizing,
            outline: document.body.style.outline,
            border: document.body.style.border,
            textDecoration: document.body.style.textDecoration,
            fontSize: document.documentElement.style.fontSize,
            overflowX: document.documentElement.style.overflowX,
            scrollBehavior: document.documentElement.style.scrollBehavior,
            scrollPaddingTop: document.documentElement.style.scrollPaddingTop,
        };

        // Apply global styles
        document.body.style.fontFamily = '"DM Sans", sans-serif';
        document.body.style.margin = "0";
        document.body.style.padding = "0";
        document.body.style.boxSizing = "border-box";
        document.body.style.outline = "none";
        document.body.style.border = "none";
        document.body.style.textDecoration = "none";
        document.documentElement.style.fontSize = "62.5%";
        document.documentElement.style.overflowX = "hidden";
        document.documentElement.style.scrollBehavior = "smooth";
        document.documentElement.style.scrollPaddingTop = "7rem";

        return () => {
            // Cleanup global styles
            document.body.style.fontFamily = originalStyles.fontFamily;
            document.body.style.margin = originalStyles.margin;
            document.body.style.padding = originalStyles.padding;
            document.body.style.boxSizing = originalStyles.boxSizing;
            document.body.style.outline = originalStyles.outline;
            document.body.style.border = originalStyles.border;
            document.body.style.textDecoration = originalStyles.textDecoration;
            document.documentElement.style.fontSize = originalStyles.fontSize;
            document.documentElement.style.overflowX = originalStyles.overflowX;
            document.documentElement.style.scrollBehavior = originalStyles.scrollBehavior;
            document.documentElement.style.scrollPaddingTop = originalStyles.scrollPaddingTop;
        };
    }, []);


    useEffect(() => {
        Aos.init({ once: true, duration: 1000 });
    }, []);

    const [isRegisterOpen, setIsRegisterOpen] = useState(false);
    const [isLoginOpen, setIsLoginOpen] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [activeHash, setActiveHash] = useState(window.location.hash || "#home");

    // ✅ Use `useCallback` to avoid re-creating functions unnecessarily
    const openRegister = useCallback(() => setIsRegisterOpen(true), []);
    const closeRegister = useCallback(() => setIsRegisterOpen(false), []);

    const openLogin = useCallback(() => {
        setIsHovered(false);
        setIsLoginOpen(true);
    }, []);

    const closeLogin = useCallback(() => setIsLoginOpen(false), []);

    // ✅ Optimize Hash Change Listener

    useEffect(() => {
        const handleHashChange = () => setActiveHash(window.location.hash);
        window.addEventListener("hashchange", handleHashChange);
        return () => window.removeEventListener("hashchange", handleHashChange);
    }, []);

    return (
        <>
            {/* Header and Main Content */}
            <Header activeHash={activeHash} openLogin={openLogin} openRegister={openRegister} isHovered={isHovered} setIsHovered={setIsHovered} />
            <Main openRegister={openRegister} />
            <Footer />

            {/* Lazy load modals to improve initial render speed */}
            <Suspense
                fallback={<div>Loading...</div>}
            >
                {isLoginOpen && <HeaderLoginOptions isLoginOpen={isLoginOpen} closeLogin={closeLogin} />}
                {isRegisterOpen && <HeaderRegisterOptions isRegisterOpen={isRegisterOpen} closeRegister={closeRegister} />}
            </Suspense>
        </>
    );
};

export default Landing;
