import React, { useState } from "react";
import { motion } from "framer-motion";
import ButtonComponent from "../../CssComponents/ButtonComponent/ButtonComponent";

const iconMap = [
    "banana", "seven", "cherry", "plum", "orange", "bell", "bar", "lemon", "melon"
];
const iconWidth = 79;
const iconHeight = 79;
const numIcons = 9;
const rollDuration = 100; // Adjusted duration for smooth roll
const maxSpins = [12, 10, 18]; // Different spin counts for each reel
const rollSound = new Audio("/sounds/roll.mp3");
const winSound = new Audio("/sounds/win.mp3");

const SlotMachine = () => {
    const [indexes, setIndexes] = useState([0, 0, 0]);
    const [rolling, setRolling] = useState(false);
    const [winClass, setWinClass] = useState("");

    const rollReel = (reelIndex, spins) => {
        return new Promise((resolve) => {
            let count = 0;
            let currentIndex = Math.floor(Math.random() * numIcons); // Start from random position

            const interval = setInterval(() => {
                count++;
                currentIndex = (currentIndex + 1) % numIcons;

                setIndexes((prevIndexes) => {
                    const newIndexes = [...prevIndexes];
                    newIndexes[reelIndex] = currentIndex;
                    return newIndexes;
                });

                if (count >= spins) {
                    clearInterval(interval);
                    resolve(currentIndex);
                }
            }, rollDuration);
        });
    };

    const rollAll = async () => {
        if (rolling) return;
        setRolling(true);
        setWinClass("");
        rollSound.play();

        const results = await Promise.all([
            rollReel(0, maxSpins[0]),
            rollReel(1, maxSpins[1]),
            rollReel(2, maxSpins[2])
        ]);

        setIndexes(results);
        setRolling(false);

        // Check win condition
        if (results[0] === results[1] && results[1] === results[2]) {
            setWinClass("win2");
            winSound.play();
        } else if (results[0] === results[1] || results[1] === results[2]) {
            setWinClass("win1");
        }
    };

    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            maxWidth: "max-content",
            padding: "1rem",
            gap: "1rem",
        }}>
            <div
                // correct className
                className={`slots ${winClass}`}
                style={{
                    position: "relative",
                    width: `${4.4 * iconWidth}px`,
                    height: `${4.4 * iconHeight}px`,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "1rem",
                    padding: `${0.3 * iconWidth}px`,
                    backgroundColor: "#b17e32",
                    background: "linear-gradient(91.94deg, #b17e32 -8.77%,   #f3e681 58.63%,  #cf9f41 107.45%)",
                    border: "1px solid #b17e32",
                    borderRadius: "10px",
                    boxSizing: "border-box",
                    boxShadow: "0 4px 10px rgba(0,0,0,0.3)"
                }}
            >
                {indexes.map((index, i) => (
                    <motion.div
                        key={i}
                        className="reel"
                        animate={{ backgroundPositionY: `${-index * iconHeight}px` }}
                        transition={{ duration: 0.5, ease: "easeInOut" }}
                        style={{
                            // backgroundColor: "red",
                            padding: "1rem",
                            position: "relative",
                            width: `${iconWidth + 5}px`,
                            height: `${3 * iconHeight + 12}px`,
                            border: "2px solid rgba(0, 0, 0, 0.5)",
                            borderRadius: "5px",
                            overflow: "hidden",
                            backgroundImage: `url(https://assets.codepen.io/439000/slotreel.webp)`,
                            backgroundSize: `auto ${numIcons * iconHeight}px`,
                            backgroundRepeat: "repeat-y",
                            boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)"
                        }}
                    />
                ))}
            </div>
            <ButtonComponent
                onClick={rollAll}
                disabled={rolling}
                buttonText={"Roll"}
            />

        </div>
    );
};

export default SlotMachine;