import React, { useEffect, useState } from "react";
import styles from "./CoursePackage.module.css";
import english from "./english.png";
import { RiArrowRightUpLine } from "react-icons/ri";
import { getCoursesWithModification } from "../../../../service/courses/getCoursesWithModification";
import CourseDrawer from "../CourseDrawer/CourseDrawer";
import { useAuth } from "../../../../provider/Auth.provider";
import ButtonComponent from "../../../../components/CssComponents/ButtonComponent/ButtonComponent";

function CoursePackage() {
    const [packageData, setPackageData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [isDrawerVisible, setIsDrawerVisible] = useState(false);
    const { user } = useAuth()


    const [selectedFilter, setSelectedFilter] = useState("");

    const handleFilterChange = (event) => {
        const value = event.target.value;
        setSelectedFilter(value);
    };

    useEffect(() => {
        const fetchPackageData = async () => {
            try {
                const data = await getCoursesWithModification();
                console.log("Data fetched:", data);

                setPackageData(data);
            } catch (err) {
                setError("Failed to load Package data");
            } finally {
                setLoading(false);
            }
        };

        fetchPackageData();
    }, []);

    const handlePackageClick = (course) => {
        setSelectedCourse(course);
        setIsDrawerVisible(true);
    };

    const closeDrawer = () => {
        setIsDrawerVisible(false);
        setSelectedCourse(null);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div className={styles.packageContainer}>

            <div className={styles.packageHeader}>
                <p className={styles.packageParagraph}>
                    With our exclusive packages, now you can be assured to acquire the best knowledge and expertise from our team of experts. We believe you can empower the world with industry-leading courses.
                </p>

                <select
                    className={styles.filterSelect}
                    value={selectedFilter}
                    onChange={handleFilterChange}
                >
                    <option value="">Select Filter</option>
                    <option value="priceHighToLow">Price: High to Low</option>
                    <option value="priceLowToHigh">Price: Low to High</option>
                    <option value="mostPopular">Most Popular</option>
                    <option value="recommended">Recommended</option>
                </select>
            </div>
            <div className={styles.packageItems}>
                {packageData.map((item, i) => (
                    <div key={i} className={styles.packageItemWrapper}>
                        <img
                            className={styles.packageImg}
                            src={item.packageImage || english}
                            alt={item.packageTitle}
                        />

                        <div className={styles.packageTitle}>{item.packageTitle}</div>

                        <div className={styles.packageDetails}>
                            {item.packageDetails.map((detail, index) => (
                                <li key={index}>
                                    <span className={styles.packageDots}></span>
                                    <span> {detail}</span>
                                </li>
                            ))}
                        </div>

                        <div className={styles.packagePoints} dangerouslySetInnerHTML={{ __html: item.desc }}>
                            
                        </div>

                        <div className={styles.hrLine}></div>

                        <div className={styles.packagePriceWrapper}>
                            <div>
                                <span className={styles.packagePrice}>
                                    {item.packagePrice}
                                </span>
                                <span className={styles.packagePriceCross}>
                                    {item.packagePriceCross}
                                </span>
                            </div>

                            <div
                                className={styles.packageBuy}
                                onClick={() => handlePackageClick(item)}
                            >
                                <RiArrowRightUpLine
                                    className={styles.packageBuyIcon}
                                />
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            {/* Drawer Component */}
            {selectedCourse && (
                <CourseDrawer
                    isVisible={isDrawerVisible}
                    onClose={closeDrawer}
                    course={selectedCourse}
                    uid={user.uid}
                />
            )}
        </div>
    );
}

export default CoursePackage;
