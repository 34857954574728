import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./Club.module.css";

function Club() {

    const CustomPrevArrow = (props) => {
        const { className, onClick } = props;
        return <div className={`${styles.customArrow} ${styles.prev}`} onClick={onClick} />;
    };

    const CustomNextArrow = (props) => {
        const { className, onClick } = props;
        return <div className={`${styles.customArrow} ${styles.next}`} onClick={onClick} />;
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        centerMode: true,
        arrows: true, // Ensure arrows are enabled
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
        responsive: [
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 1,
                }
            },
        ]
    };

    const clubData = [
        { videoId: "XxiOxCsgfRw", name: "Affiliate Ritual Event 1", timestamp: 0 },  // No. 1 Video
        { videoId: "yeuuMOPVdSk", name: "Affiliate Ritual Event 2", timestamp: 0 },  // No. 2 Video (Starts at 4704s)
        { videoId: "2Lg7-B4mnkk", name: "Affiliate Ritual Event 2", timestamp: 0 },  // No. 2 Video (Starts at 4704s)
        { videoId: "GcdbhiMxkIA", name: "Affiliate Ritual Event 2", timestamp: 0 },  // No. 2 Video (Starts at 4704s)
        { videoId: "bdLHtrJOOeY", name: "Affiliate Ritual Event 2", timestamp: 0 },  // No. 2 Video (Starts at 4704s)
        { videoId: "PpkcXIPMFME", name: "Affiliate Ritual Event 2", timestamp: 0 },  // No. 2 Video (Starts at 4704s)
    ];

    return (
        <div>
            <p className={styles.clubParagraph}>
                Hear From Our Club Leader And Club Member
            </p>

            <div className="slider-container">
                <Slider {...settings} className={styles.clubItemContainer}>
                    {clubData.map((item, i) => (
                        <LazyYouTube key={i} videoId={item.videoId} />
                    ))}
                </Slider>
            </div>
        </div>
    );
}

export default Club;

// ✅ Lazy Load YouTube Iframes
const LazyYouTube = ({ videoId }) => {
    const [isPlaying, setIsPlaying] = useState(false);

    return (
        <div className={styles.clubItem} onClick={() => setIsPlaying(true)}>
            {!isPlaying ? (
                <img
                    className={styles.clubImg}
                    src={`https://img.youtube.com/vi/${videoId}/hqdefault.jpg`}
                    alt="Video Thumbnail"
                    loading="lazy"
                />
            ) : (
                <iframe
                    className={styles.clubVideo} // Use new class
                    src={`https://www.youtube.com/embed/${videoId}?autoplay=1&controls=1&modestbranding=1&rel=0`}
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                ></iframe>
            )}
        </div>
    );
};

